// section page de contact
#gib-contact {
  h1 {
    font-size: 31px;
    text-align: center;
    margin-top: 40px;
    margin-bottom: 40px;
  }
  .glyphicon {
    font-size: 35px;
  }
  .gib-contact-titre {
    color: $main-color;
    font-size: 22px;
  }
  .well {
    min-height: 220px;
  }
  .gib-contact {
    margin: 50px 0;
  }
  textarea {
    max-height: 100%;
    max-width: 100%;
    resize: vertical;
  }
  p {
    font-size: 16px;
  }
  dl {
    margin-left: 20px;
  }
  #num_vert {
    max-width: 250px;
    margin-top: 15px;
  }
  //gère affichage mobile
  @media only screen
  and (max-device-width : $screen-sm-max) {
    #num_vert {
      margin-top: 0;
      max-width: 200px;
    }
    .gib-contact-titre {
      font-size: 18px;
    }
    #gib-sms-msg {
      p {
        margin: 0;
      }
    }
  }
}

#contact-form > .gib-contact {
  padding: 21px;
  background-color: #F3F3F3;
  border: 1px solid lightgrey;
}
