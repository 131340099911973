// Section espace client / index de l'espace client / page de login
#ClientSpace {
  h2, h3{
    text-align:center;
    color:$main-color;
  }
  .container{
    min-height:650px;
  }
}


//Modification de l'agencement de la page login de l'espace client
#login {
  min-height: 325px;
  padding: 50px;
  @media (max-width: $screen-md-min) {
    min-height: 0;
  }
  margin: 50px auto;
}

/*Section client Index*/
#clientIndex {

  .grey_container {
    margin-bottom: 15px;
    padding:20px;
    background-color: #f3f3f3;
    border-radius: 10px;
  }
  ul {
    padding:35px 15px 0 0;
  }
  li {
    list-style-type:none;
  }
  h4 {
    text-align:center;
  }
  .btn {
    margin:5px;
    font-size:15px;
    padding-top: 6px;
    white-space:normal;
  }
  //resize pour fenêtre ouverte à moité
  @media(max-width: 500px) {
    .btn {
      font-size: 13px;
    }
  }
  h4 {
    margin: 20px 0;
  }
  #index-titre {
    margin-bottom: 75px;
  }
}
