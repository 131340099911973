/*Section new password*/
#newPass {
  h3 {
    margin-bottom:20px;
  }
  .btn {
    width:120px;
    height: 45px;
    font-size: 20px;
  }
  #button-bar {
    margin-top: 75px;
  }
  .inline {
    display : inline-block;
  }
  label {
    display: block;
    padding-bottom: 9px;
  }
  ul {
    padding:0;
    margin:0;
    list-style-type:none;
  }
  li {
    margin-left:2px;
    float:left; /*pour IE*/
  }
  ul li a {
    display:block;
    float:left
  }
  ul li input {
    display:block;
  }
}
