//Section faq
#faq_v2 {

  h1 {
    text-align: center;
    margin-top: 40px;
    margin-bottom: 40px;
  }

  padding-top:50px;
  .faq_section {
    display: none;
    padding-left:40px;
    padding-top:40px;
  }
  #faq_menu {
    padding-top:45px;
  }
  #faq_foot {
    padding:50px;
  }
  @media only screen
  and (max-device-width : 800px){
    #faq_menu {
      padding-top:90px;
    }
  }
}
