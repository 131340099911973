#desabo_container{

  hr{
    margin-top: 0px;
  }

  .arrow_container{
    text-align: right;
    float: right;
    i{
      font-size: 50px;
    }
  }

  h1 {
    text-align: center;
    margin-bottom: 40px;
    margin-top: 40px;
    font-size: 31px;
  }

  h3{
    margin-top: 0px;
  }

  h4{
    font-weight: normal;
    font-family: inherit;
  }

  h5{
    color: $main-color;
    font-size: 22px;
    font-weight: bold;
  }

  h6{
    font-size: 22px;
    margin-top: -10px;
    padding-bottom: 30px;
  }

  .grey_container{
    margin-top:15px;
    margin-bottom: 15px;
    padding: 20px;
    background-color: #f3f3f3;
    border-radius: 10px;
  }

  #email_inpt{
    width: 60%;
  }

  #form_inputs_container{
    width: 100%;
  }

  label{
    font-weight: normal;
    padding-right: 20px;
  }
  input.btn{
    font-weight: bold;
  }

  ul.service_client{
    list-style: none;
    padding-left: 10px;

    .badge{
      padding: 10px;
      border-radius: 100px;
      font-size: 1.05em;
      margin-bottom: 5px;
      background-color: $main-color;
    }
    img{
      margin-left:40px;
    }
  }

  .spacer{
    width: 100%;
    height: 150px;
  }
}
