//Section historique Paiment
#histPay {
  h3 {
    margin-bottom:80px;
  }
  .alert-danger {
    margin-top: 75px;
  }

  @media(max-device-width : $screen-sm-max) {
    tr {
      font-size:12px;
    }
  }

}
