/*Section historique de localisations*/
#histLoc {
  h3 {
    margin-bottom:40px;
  }
  td p {
    margin-bottom:0;
  }
  tr {
    height:36px;
  }
  .btn-lg {
    margin-bottom: 20px;
  }
  .table {
    margin-top:75px;
  }
  .alert-danger {
    margin-top: 75px;
  }
  .nota-bene {
    font-style:italic;
    font-size: 12px;
  }
}
